import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import { Link } from 'gatsby';
import Image from '../../components/Image';
import Quicklinks from '../../components/Quicklinks';
import StudentQuote from '../../components/StudentQuote';

const EventGalleryVideos = () => (
  <Layout
    title="Inside the Challenge"
    className="annual-challenge event-gallery"
  >
    <div className="hero-container">
      <div className="hero-container__gradient bg-gradient-orange"></div>
      <div className="hero-container__titleImage">
        {/* Breadcrumbs */}
        <ul className="breadcrumbs">
          <li className="breadcrumb">
            <Link to="../../annual-challenge/about-the-challenge/">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb active">Inside the Challenge</li>
        </ul>
        <h1 className="page-title">Inside the Challenge</h1>
        <Image filename="hero-events.png" className="hero-image" />
      </div>
    </div>
    {/* Event Gallery Intro */}
    <Section>
      <Container>
        <Row>
          <Column size={8} offset={2} className="global-intro">
            <h2>Welcome to the 3M Young Scientist Challenge</h2>
            <h4>
              Go inside the premier middle school science competition with a
              collection of multimedia resources that bring to life the
              challenge journey. Hear firsthand from students and 3M team
              members who have participated in the 3M Young Scientist Challenge
              to learn more about what it takes to become America’s Top Young
              Scientist.
            </h4>
          </Column>
        </Row>
        {/* Event Gallery Intro */}
        {/* Resolve this after launch 
        <Row>
          <Column size={10}>
            <ul className="filter-tabs annual-challenge">
              <li className="filter-tabs__tab selected">
                <Link to="#">Videos</Link>
              </li>
              <li className="filter-tabs__tab">
                <Link to="../event-gallery-photos">Photos</Link>
              </li>
            </ul>
          </Column>
        </Row> */}
        <Row>
          <Column size={10} className="mt-4">
            <Quicklinks
              className="pl-0"
              title="Quicklinks:"
              smoothScroll
              links={[
                {
                  target: '#presentations',
                  label: 'Presentations',
                },
                {
                  target: '#highlights',
                  label: 'Highlights',
                },
                {
                  target: '#webinars',
                  label: 'Webinars',
                },
              ]}
            />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Presentations */}
    <Section id="presentations">
      <Container>
        <Row className="gallery-heading">
          <h2>Presentations</h2>
        </Row>
        <Row className="gallery-content">
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="2021-finalist-thumb-aadrit-talukdar.png" />
              </div>
              <div className="profile-card__content">
                <h3 className="text-26">3M 2021 YSC Finalist Presentations</h3>
                <p>
                  Watch the 2021 YSC Finalist presentations as the ten finalists
                  describe their exciting ideas.
                </p>
                <Link
                  to="/annual-challenge/event-gallery/live-events/3M-2021-YSC-Finalist-Presentations"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="2020-finalist-thumb-awards-ceremony.png" />
              </div>
              <div className="profile-card__content">
                <h3 className="text-26">3M 2020 YSC Finalist Presentations</h3>
                <p>
                  Watch the 2020 YSC Finalist presentations as the ten finalists
                  describe their exciting ideas.
                </p>
                <Link
                  to="/annual-challenge/event-gallery/live-events/3M-2020-YSC-Finalist-Presentations"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="2018-finalist-thumbnail-rishab-jain.jpg" />
              </div>
              <div className="profile-card__content">
                <h3 className="text-26">3M 2018 YSC Finalist Presentations</h3>
                <p>
                  Watch the 2018 YSC Finalist presentations as the ten finalists
                  describe their exciting ideas.
                </p>
                <Link
                  to="/annual-challenge/event-gallery/live-events/3M-2018-YSC-Finalist-Presentations"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
        <Row className="gallery-content">
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="2017-finalist-thumbnail-allie-weber.jpg" />
              </div>
              <div className="profile-card__content">
                <h3 className="text-26">3M 2017 YSC Finalist Presentations</h3>
                <p>
                  Watch the 2017 YSC Finalist presentations as the ten finalists
                  describe their exciting ideas.
                </p>
                <Link
                  to="/annual-challenge/event-gallery/live-events/3M-2017-YSC-Finalist-Presentations"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="2016-finalist-thumbnail-opening-remarks.jpg" />
              </div>
              <div className="profile-card__content">
                <h3 className="text-26">3M 2016 YSC Finalist Presentations</h3>
                <p>
                  Watch the 2016 YSC Finalist presentations as the ten finalists
                  describe their exciting ideas.
                </p>
                <Link
                  to="/annual-challenge/event-gallery/live-events/3M-2016-YSC-Finalist-Presentations"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="2015-finalist-thumbnail-opening-remarks.jpg" />
              </div>
              <div className="profile-card__content">
                <h3 className="text-26">3M 2015 YSC Finalist Presentations</h3>
                <p>
                  Watch the 2015 YSC Finalist presentations as the ten finalists
                  describe their exciting ideas.
                </p>
                <Link
                  to="/annual-challenge/event-gallery/live-events/3M-2015-YSC-Finalist-Presentations"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
        <Row className="gallery-content">
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="2014-finalist-thumbnail-final-event.jpg" />
              </div>
              <div className="profile-card__content">
                <h3 className="text-26">3M 2014 YSC Finalist Presentations</h3>
                <p>
                  Watch the 2014 YSC Finalist presentations as the ten finalists
                  describe their exciting ideas.
                </p>
                <Link
                  to="/annual-challenge/event-gallery/live-events/3M-2014-YSC-Finalist-Presentations"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Highlights */}
    <Section id="highlights">
      <Container>
        <Row className="gallery-heading">
          <h2>Highlights</h2>
        </Row>
        <Row className="gallery-content">
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="image-americas-top-young-scientist.jpg" />
              </div>
              <div className="profile-card__content">
                <h3>What is it like to be America's Top Young Scientist?</h3>
                <p>
                  Hear from Top Young Scientist Gitanjali Rao about what it was
                  like to win the 2017 Young Scientist Challenge.
                </p>
                <Link
                  to="/annual-challenge/event-gallery/highlights/top-young-scientist"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="image-why-we-love-science.jpg" />
              </div>
              <div className="profile-card__content">
                <h3>Why We Love Science</h3>
                <p>
                  Do you love science? Hear from past Young Scientist Finalists
                  and Mentors about their passion for science and discovery.
                </p>
                <Link
                  to="/annual-challenge/event-gallery/highlights/why-we-love-science"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="image-importance-of-mentorship.jpg" />
              </div>
              <div className="profile-card__content">
                <h3>The Importance of Mentorship</h3>
                <p>
                  Mentorship is important for all scientists and the cornerstone
                  of The 3M Young Scientist Challenge.
                </p>
                <Link
                  to="/annual-challenge/event-gallery/highlights/the-importance-of-mentorship"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
        <Row className="gallery-content">
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="image-shaping-futures.jpg" />
              </div>
              <div className="profile-card__content">
                <h3>Shaping Futures</h3>
                <p>
                  Hear how the Young Scientist Challenge is leading the way in
                  impacting the next generation of scientists.
                </p>
                <Link
                  to="/annual-challenge/event-gallery/highlights/shaping-futures"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="image-innovation-taking-shape.jpg" />
              </div>
              <div className="profile-card__content">
                <h3>Innovation Taking Shape</h3>
                <p>
                  Learn how the Young Scientist Challenge 2015 finalists were
                  impacted by science and how they are inspired to apply science
                </p>
                <Link
                  to="/annual-challenge/event-gallery/highlights/innovation-taking-shape"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="image-journey-to-3m.jpg" />
              </div>
              <div className="profile-card__content">
                <h3>Journey to 3M</h3>
                <p>
                  Finalists know there’s no place like 3M to spark imagination.
                  Imagine yourself as part of one of the world’s largest
                  innovators.
                </p>
                <Link
                  to="/annual-challenge/event-gallery/highlights/journey-to-3m"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
        <Row className="gallery-content">
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="image-meet-the-mentors.jpg" />
              </div>
              <div className="profile-card__content">
                <h3>Meet the Mentors</h3>
                <p>
                  3M scientists are true innovators – and the best mentors –
                  you’ll ever meet. Get to know a few now.
                </p>
                <Link
                  to="/annual-challenge/event-gallery/highlights/meet-the-mentors"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="image-voyage-of-ideas.jpg" />
              </div>
              <div className="profile-card__content">
                <h3>Voyage of Ideas</h3>
                <p>
                  Time travel through past competitors’ inventions and ideas.
                  Who knows? They just might inspire your own.
                </p>
                <Link
                  to="/annual-challenge/event-gallery/highlights/voyage-of-ideas"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="image-anatomy-of-a-challenge.jpg" />
              </div>
              <div className="profile-card__content">
                <h3>Anatomy of a Challenge</h3>
                <p>
                  Discover what the Young Scientist Challenge is made of – and
                  why we’re the nation’s premier middle school science
                </p>
                <Link
                  to="/annual-challenge/event-gallery/highlights/anatomy-of-challenge"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
        <Row className="gallery-content">
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="image-why-science.jpg" />
              </div>
              <div className="profile-card__content">
                <h3>Why Science?</h3>
                <p>
                  Science is behind all of 3M’s innovations. Check out why
                  students and scientists are passionate about both.
                </p>
                <Link
                  to="/annual-challenge/event-gallery/highlights/why-science"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="image-importance-of-stem.jpg" />
              </div>
              <div className="profile-card__content">
                <h3>The Importance of STEM</h3>
                <p>
                  Hear from experts in the field as they explain the importance
                  of STEM education.
                </p>
                <Link
                  to="/annual-challenge/event-gallery/highlights/importance-of-stem"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="image-life-of-scientist.jpg" />
              </div>
              <div className="profile-card__content">
                <h3>The Life of a Scientist</h3>
                <p>
                  Curious about a day in the life of a 3M scientist? Get to know
                  Dr. Olester Benson and what he enjoys most about his career.
                </p>
                <Link
                  to="/annual-challenge/event-gallery/highlights/life-of-scientist"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
        <Row className="gallery-content">
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="image-who-inspired-you.jpg" />
              </div>
              <div className="profile-card__content">
                <h3>Who Inspired You?</h3>
                <p>
                  Even scientists at 3M had mentors; learn who inspired the
                  bright minds at 3M to explore careers in science and what they
                  believe is the key aspect to success in their profession.
                  Here's a hint: you won't find it in a textbook!
                </p>
                <Link
                  to="/annual-challenge/event-gallery/highlights/who-inspired-you"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Webinars */}
    <Section id="webinars">
      <Container>
        <Row className="gallery-heading">
          <h2>Webinars</h2>
        </Row>
        <Row className="gallery-content">
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="storyboarding-successful-screen-debut.jpg" />
              </div>
              <div className="profile-card__content">
                <h3 className="text-26">
                  Storyboarding for a Successful Screen Debut
                </h3>
                <p>
                  This webinar from the Discovery Education 3M Young Scientist
                  Challenge introduces students to storyboarding and shows how
                  it can help in making a Young Scientist Challenge entry video.
                </p>
                <Link
                  to="/annual-challenge/event-gallery/webinars/storyboarding-successful-screen-debut"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="process-this-webinar-thumbnail.jpg" />
              </div>
              <div className="profile-card__content">
                <h3 className="text-26">
                  Process this! Analyze that! Process Skills Strategies for
                  Science
                </h3>
                <p>
                  Discovery Education's Patti Duncan shares this fun webinar on
                  developing process skills in students, especially in science.
                  Patti will tie in the 3M Discovery Education Young Scientist
                  Challenge at the end.
                </p>
                <Link
                  to="/annual-challenge/event-gallery/webinars/process-this-analyze-that"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="webinar-with-2012-winner-deepika-kurup.jpg" />
              </div>
              <div className="profile-card__content">
                <h3 className="text-26">
                  All About the Young Scienitst Challenge: Webinar with 2012
                  Winner Deepika Kurup
                </h3>
                <p>
                  Learn all about the Discovery Education 3M Young Scientist
                  Challenge in this webinar featuring 2012 Grand Prize winner
                  Deepika Kurup!
                </p>
                <Link
                  to="/annual-challenge/event-gallery/webinars/ysc-webinar-2012-winner-deepika-kurup"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
        <Row className="gallery-content">
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="webinar-featuring-2011-winner-braeden-benedict.jpg" />
              </div>
              <div className="profile-card__content">
                <h3 className="text-26">
                  Young Scientist Challenge 101: Webinar featuring 2011 Winner
                  Braeden Benedict
                </h3>
                <p>
                  Learn all about the Young Scientist Challenge in this 2012
                  webinar featuring 2011 Grand Prize winner Braeden Benedict.
                </p>
                <Link
                  to="/annual-challenge/event-gallery/webinars/ysc-webinar-2011-winner-braeden-benedict"
                  className="profile-card__cta"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Student Stories Quote */}
    <Section className="student-stories">
      <StudentQuote
        image="Caroline-Crouchley-2019-Finalist-Quote.png"
        name="Caroline Crouchley"
        year="Finalist 2019"
        body="I believe communicating and collaborating with others is the best way to
      solve important global issues such as global warming and climate change."
        path="/annual-challenge/finalists-mentors-judges/finalists/caroline-crouchley"
        classNames="bg-gradient-orange cta__link no-arrow"
        calloutText="Learn what sparked Caroline's journey"
      ></StudentQuote>
    </Section>
  </Layout>
);

export default EventGalleryVideos;
